const elms = {
	splash: document.getElementById('splash'),
	splashLogo: document.getElementById('splashLogo'),
	contents: document.getElementById('contents'),
	headerLogo: document.getElementById('headerLogo'),
};

const slider = {
	swiper: '',
	timer: null,
	inSliders: null,
	sp: {
		options: {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'coverflow',
			on: {
				init: initInSlider,
			}
		},
		active: false,
	},
	pc: {
		options: {
			loop: true,
			slidesPerView: 'auto',
			// spaceBetween: 35,
			centeredSlides: true,
			effect: 'slide',
			mousewheel: true,
			allowTouchMove: false,
			navigation: {
				prevEl: '.swiper-button-prev',
				nextEl: '.swiper-button-next',
			},
			on: {
				init: initInSlider,
			}
		},
		active: false,
	},
	in: {
		options: {
			autoplay: false,
			loop: false,
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			preventClicksPropagation: false,
			allowTouchMove: false,
			observeSlideChildren: true,
			pagination: {
				el: '.inSlider-pagination',
			},
			on: {
				init: function (e) {
					e.pagination.bullets[0].classList.remove('swiper-pagination-bullet-active');
				},
			},
		},
	}
};

const inSliderChangeFunc = (e) => {
	slider.inSliders[e.previousIndex].autoplay.stop();
	slider.inSliders[e.previousIndex].pagination.bullets.forEach((el) => {
		el.classList.remove('swiper-pagination-bullet-active');
	});
	slider.inSliders[e.activeIndex].slideTo(0);
	slider.inSliders[e.activeIndex].pagination.bullets[0].classList.add('swiper-pagination-bullet-active');
	slider.inSliders[e.activeIndex].autoplay.start();
};

const setSwiper = function () {
	if (window.innerWidth <= 480) {
		if (slider.sp.active) {
			return;
		} else {
			if (slider.pc.active) {
				slider.swiper.destroy();
			}
			slider.swiper = new Swiper('.js_slider', slider.sp.options);
			slider.sp.active = true;
			slider.pc.active = false;
			slider.swiper.on('slideChange', inSliderChangeFunc);
		}
	} else {
		if (slider.pc.active) {
			return;
		} else {
			if (slider.sp.active) {
				slider.swiper.destroy();
			}
			slider.swiper = new Swiper('.js_slider', slider.pc.options);
			slider.pc.active = true;
			slider.sp.active = false;
			slider.swiper.on('slideChange', inSliderChangeFunc);
		}
	}
}

window.addEventListener('load', setSwiper, false);
window.addEventListener('resize', setSwiper, false);

const controlBtns = {
	prev: document.querySelectorAll('.inSlider-button-prev'),
	next: document.querySelectorAll('.inSlider-button-next'),
}

controlBtns.prev.forEach((el, i) => {
	el.addEventListener('click', () => {
		if (window.innerWidth > 480) {
			return false;
		}
		if (!el.closest('.swiper-slide').classList.contains('swiper-slide-active')) {
			return false;
		}
		if (!slider.inSliders[i].slidePrev()) {
			slider.swiper.slidePrev();
		}
		slider.inSliders[i].autoplay.start();
	}, false);
});

controlBtns.next.forEach((el, i) => {
	el.addEventListener('click', () => {
		if (window.innerWidth > 480) {
			return false;
		}
		if (!slider.inSliders[i].slideNext()) {
			slider.swiper.slideNext();
			clearTimeout(slider.timer);
		}
		if (!slider.inSliders[i].isEnd) {
			slider.inSliders[i].autoplay.start();
		}
	}, false);
});

function initInSlider() {
	slider.inSliders = new Swiper('.js_inSlider', slider.in.options);

	setTimeout(function () {
		const i = (window.innerWidth <= 480) ? 0 : slider.inSliders.length / 3;
		slider.inSliders[i].autoplay.start();
		slider.inSliders[i].pagination.bullets[0].classList.add('swiper-pagination-bullet-active');
	}, 50);

	for (let i = 0; i < slider.inSliders.length; i++) {
		slider.inSliders[i].on('slideChange', function (e) {
			if (e.isEnd) {
				e.autoplay.stop();
				clearTimeout(slider.timer);
				slider.timer = setTimeout(() => {
					slider.swiper.slideNext();
				}, 3000);
			}
		});
		slider.inSliders[i].on('autoplayStart', function (e) {
			if (e.slides.length === 1) {
				clearTimeout(slider.timer);
				slider.timer = setTimeout(() => {
					slider.swiper.slideNext();
				}, 3000);
			}
		});
	}
	const items = document.querySelectorAll('.item');

	items.forEach((el, i) => {
		el.addEventListener('click', (e) => {
			if (window.innerWidth <= 480) {
				return false;
			}
			let d = i - slider.swiper.activeIndex;
			for (let j = 0; j < Math.abs(d); j++) {
				if (d < 0) {
					setTimeout(function () {
						slider.swiper.slidePrev();
					}, j * 350);
				} else {
					setTimeout(function () {
						slider.swiper.slideNext();
					}, j * 350);
				}
			}
		}, false);
	});

	if (slider.inSliders.length === items.length) {
		elms.splashLogo.classList.add('show');
		setTimeout(() => {
			slider.swiper.slideTo(0, 0);
			elms.splashLogo.classList.add('hide');
		}, 750);
		setTimeout(() => {
			pageShow();
		}, 1250);
	}
}

function pageShow() {
	elms.splash.classList.add('hide');
	setTimeout(() => {
		elms.contents.classList.add('show');
		slider.swiper.slideToLoop(0, 1500);
	}, 200);
	setTimeout(() => {
		elms.headerLogo.classList.add('show');
	}, 1200);
}

// elms.splash.classList.add('hide');
// elms.contents.classList.add('show');
